<template>
  <div class="rounded-[10px] bg-white p-20">
    <div class="mb-10 flex items-center justify-between">
      <div class="w-1/2">
        <el-tabs v-model="activeTab" class="indexLadder-tabs" @tab-change="changeActiveTab">
          <el-tab-pane :label="$t('饰品榜单')" name="skin"></el-tab-pane>
          <el-tab-pane :label="$t('挂刀/搬砖榜单')" name="hanging"></el-tab-pane>
          <el-tab-pane :label="$t('收藏夹榜单')" name="collect"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="cursor-pointer text-right text-gray hover:text-blue" @click="toLadders">{{ $t('查看更多') }}>>></div>
    </div>
    <div class="second-tabs flex justify-between border-b border-gray-lighter pb-10">
      <div class="tab-content flex h-40 gap-10 rounded-[10px] bg-blue-50 p-3">
        <div
          v-for="tab in tabListConfig[activeTab]"
          :key="tab.value"
          class="tab-item cursor-pointer px-30 py-7"
          :class="tab.value === activeSecondTab ? 'rounded-[10px] bg-white text-blue' : ''"
          @click="changeActiveSecondTab(tab)"
        >
          {{ tab.name }}
        </div>
      </div>
      <div>
        <div v-if="activeTab === 'collect'" class="flex h-40 w-400 justify-between overflow-hidden rounded-[10px] border border-blue">
          <div>
            <input v-model="collectKeyword" type="text" class="h-40 w-300 pl-10 outline-none" :placeholder="$t('请输入收藏夹名称或关注码')" />
          </div>
          <div class="pointer flex h-40 w-40 items-center justify-center bg-blue" @click="getCollectionList">
            <i class="iconfont icon-sousuo text-white"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'skin'">
      <el-table v-show="tableData?.length > 0" :data="tableData" :show-header="false" style="width: 100%" :row-style="{ height: '122px' }">
        <el-table-column label="排名" width="50">
          <template #default="scope">
            <div class="text-center"><rankLabel :rank="scope.$index + 1"></rankLabel></div>
          </template>
        </el-table-column>
        <el-table-column label="名称" width="800">
          <template #default="scope">
            <div class="flex">
              <div class="item-img mr-10 flex h-74 w-109 items-center justify-center rounded-[10px] p-6">
                <img class="" :src="scope.row.itemInfoVO?.imageUrl" alt="" />
              </div>
              <div>
                <div class="mb-5 text-16 text-black">
                  <a :href="`/cs2/${encodeURIComponent(scope.row.itemInfoVO.marketHashName)}`" target="_blank">
                    {{ scope.row.itemInfoVO?.name }}
                  </a>
                </div>
                <div class="mb-5 text-16 text-[#FD802C]">{{ moneyUnit }}{{ transformCurrency(scope.row?.sellPriceInfoVO?.price) }}</div>
                <div class="text-color-gray">
                  <span class="mr-20">
                    {{ $t('价格涨幅') }}:
                    <span class="ml-5 mr-5" :class="updownClass(scope.row.sellPriceInfoVO[`diff${dayTypeEmnu(pageQuery.dataRange)}`])">
                      {{ scope.row.sellPriceInfoVO[`diff${dayTypeEmnu(pageQuery.dataRange)}`] > 0 ? '+' : '' }}
                      {{ scope.row.sellPriceInfoVO[`diff${dayTypeEmnu(pageQuery.dataRange)}`] }}%
                    </span>
                    <el-popover placement="bottom" trigger="hover" :width="300" :popper-style="{ padding: 0 }">
                      <template #reference>
                        <i class="iconfont icon-changjianwentibeifen hover:text-blue"></i>
                      </template>
                      <template #default>
                        <priceDropdown :info="scope.row.sellPriceInfoVO" :data-range="pageQuery.dataRange"></priceDropdown>
                      </template>
                      <!-- <el-button slot="reference">content</el-button> -->
                    </el-popover>
                  </span>
                  <span class="mr-20">
                    {{ $t('在售数涨幅') }}:
                    <span class="ml-5 mr-5" :class="updownClass(scope.row.sellNumsInfoVO[`sellNums${dayTypeEmnu(pageQuery.dataRange)}Rate`])">
                      {{ scope.row.sellNumsInfoVO[`sellNums${dayTypeEmnu(pageQuery.dataRange)}Rate`] > 0 ? '+' : '' }}
                      {{ scope.row.sellNumsInfoVO[`sellNums${dayTypeEmnu(pageQuery.dataRange)}Rate`] }}%
                    </span>
                    <el-popover placement="bottom" trigger="hover" :width="290" :popper-style="{ padding: 0 }">
                      <template #reference>
                        <i class="iconfont icon-changjianwentibeifen hover:text-blue"></i>
                      </template>
                      <template #default>
                        <sellNumsDropdown :info="scope.row.sellNumsInfoVO" :data-range="pageQuery.dataRange"></sellNumsDropdown>
                      </template>
                      <!-- <el-button slot="reference">content</el-button> -->
                    </el-popover>
                  </span>
                  <span class="mr-20">
                    {{ $t('成交量') }}:
                    <span class="ml-5 mr-5" :class="updownClass(scope.row.transactionCountInfoVO.transactionCountRate48Hours)">
                      {{ scope.row.transactionCountInfoVO.transactionCountRate48Hours > 0 ? '+' : '' }}
                      {{ scope.row.transactionCountInfoVO.transactionCountRate48Hours }}%
                    </span>
                    <el-popover placement="bottom" trigger="hover" :width="250">
                      <template #reference>
                        <i class="iconfont icon-changjianwentibeifen hover:text-blue"></i>
                      </template>
                      <template #default>
                        <transicationDropdown :info="scope.row.transactionCountInfoVO" :data-range="pageQuery.dataRange"></transicationDropdown>
                      </template>
                      <!-- <el-button slot="reference">content</el-button> -->
                    </el-popover>
                  </span>
                  <span class="mr-20">
                    {{ $t('存世量') }}:
                    <span class="ml-5 mr-5 text-gray-light">{{ scope.row.surviveNum ?? '-' }}</span>
                  </span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="价格" width="310">
          <template #default="scope">
            <ladderCenterContent :info="scope.row" :type="activeSecondTab.dataField" :day-type="pageQuery.dataRange"></ladderCenterContent>
          </template>
        </el-table-column>
        <el-table-column :fixed="'right'" label="涨跌" width="200">
          <template #default="scope">
            <div class="text-right">
              <div class="mb-10">
                <div
                  class="line mr-10 inline-block h-20 rounded-[10px] px-5 text-12 leading-[20px]"
                  :style="{ backgroundColor: scope.row.updateFrequency ? hex2rgba(scope.row?.updateFrequency?.background) : '' }"
                  v-html="scope.row.updateFrequency?.style"
                ></div>
                <span class="text-gray">{{ formateDate(scope.row.updateTime) }}</span>
              </div>

              <laddersLowestPrice :list="scope.row.platformInfoList" :info="scope.row.itemInfoVO"></laddersLowestPrice>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="activeTab === 'hanging'">
      <indexLadderHangingList :table-data="hangingList" :type="activeSecondTab"></indexLadderHangingList>
    </div>
    <div v-if="activeTab === 'collect'">
      <indexLadderCollectList :table-data="collectionList" :type="activeSecondTab"></indexLadderCollectList>
    </div>
  </div>
</template>
<script setup>
import { dayTypeEmnu } from '~/constants/ladders/config.js'
import { hexToRgba } from '~/utils/index.js'
import priceDropdown from '~/pages/ladders/components/price-dropdown.vue'
import sellNumsDropdown from '~/pages/ladders/components/sellNums-dropdown.vue'
import transicationDropdown from '~/pages/ladders/components/transication-dropdown.vue'
import ladderCenterContent from '~/pages/ladders/components/ladder-center-content.vue'
import laddersLowestPrice from '~/pages/ladders/components/ladders-lowestPrice.vue'
import rankLabel from '~/components/common/rankLabel.vue'
import indexLadderHangingList from '~/pages/allnewIndexComponents/indexLadder-hangingList.vue'
import indexLadderCollectList from '~/pages/allnewIndexComponents/indexLadder-collectList.vue'
import { getCollectionRank, getKnifeRank, rankingPage } from '~/api/steam'
import dayjs from 'dayjs'

const activeTab = ref('skin')
const activeSecondTab = ref('onsaleRise')

const moneyUnit = monetaryUnit()
const collectKeyword = ref('')

const { t: $t } = useI18n()
const tabListConfig = ref({
  skin: [
    {
      name: $t('在售价涨幅榜'),
      value: {
        dataField: 'priceRate',
        sortType: 'DESC',
        dataRange: 'ONE_DAY'
      }
    },
    {
      name: $t('在售价跌幅榜'),
      value: {
        dataField: 'priceRate',
        sortType: 'ASC',
        dataRange: 'ONE_DAY'
      }
    },
    {
      name: $t('成交榜'),
      value: {
        dataField: 'transactionCount',
        sortType: 'DESC',
        dataRange: 'ONE_DAY'
      }
    },
    {
      name: $t('热度榜单'),
      value: {
        dataField: 'hotCount',
        sortType: 'ASC',
        dataRange: 'ONE_DAY',
        rangeConditionList: null
      }
    },
    {
      name: $t('热度上升榜单'),
      value: {
        dataField: 'hotUp',
        sortType: 'DESC',
        dataRange: 'ONE_DAY',
        rangeConditionList: null
      }
    }
  ],
  hanging: [
    {
      name: $t('挂刀榜'),
      value: 'knife'
    },
    {
      name: $t('搬砖榜'),
      value: 'brick'
    }
  ],
  collect: [
    {
      name: $t('热度榜单'),
      value: 'hotCount'
    },
    {
      name: $t('热度上升榜单'),
      value: 'hotUp'
    }
  ]
})

const topActiveName = ref('price')
const ladderTabActive = ref('priceRate')
const pageQuery = reactive({
  page: 1,
  pageSize: 15,
  nextId: '',
  dataRange: 'ONE_DAY',
  dataField: ladderTabActive.value,
  sortType: 'DESC',
  folder: null,
  rangeConditionList: [
    {
      field: 'price',
      range: null,
      minVal: 100
    },
    {
      field: 'sellNums',
      range: null,
      minVal: 50
    },
    {
      field: 'transactionCount',
      range: 'THREE_DAYS',
      minVal: 30
    }
  ]
})

const collectionQuery = reactive({
  content: '',
  dataField: 'hotCount',
  pageSize: 15,
  nextId: ''
})

const hangingQuery = reactive({
  page: 1,
  pageSize: 15,
  type: 'swap',
  wantToGet: 'STEAM_BALANCE',
  purchasePlan: '',
  salePlan: 'STEAM_SELL_PRICE',
  minSellPrice: 1,
  maxSellPrice: 5000,
  minTransactionCount: 30,
  platformList: ['C5', 'YOUPIN', 'BUFF']
})

const banzhuanQuery = reactive({
  page: 1,
  pageSize: 15,
  type: 'swap',
  wantToGet: 'PLATFORM_BALANCE',
  purchasePlan: 'STEAM_SELL_PRICE',
  salePlan: 'PLATFORM_SELL_PRICE',
  minSellPrice: 1,
  maxSellPrice: 5000,
  minTransactionCount: 30,
  platformList: ['C5', 'YOUPIN', 'BUFF']
})

const hangingList = ref([])
const collectionList = ref([])
const tableData = ref([])

onMounted(() => {
  changeActiveSecondTab(tabListConfig.value.skin[0])
})

const transformCurrency = num => {
  return currencyTransform(num)
}

const updownClass = num => {
  if (num === 0) return ''
  return num > 0 ? 'text-red' : 'text-green'
}

const hex2rgba = hex => {
  return hexToRgba(hex, 0.1)
}

const formateDate = date =>
  computed(() => {
    return dayjs(date * 1000).format('MM-DD HH:mm')
  })

const changeActiveTab = tab => {
  console.log('changeActiveTab', tab)
  activeTab.value = tab
  activeSecondTab.value = tabListConfig.value[tab][0].value
  if (tab === 'skin') {
    getSkinList()
  }
  if (tab === 'hanging') {
    getHangingList()
  }
  if (tab === 'collect') {
    getCollectionList()
  }
}

const changeActiveSecondTab = tab => {
  console.log('changeActiveSecondTab', tab)
  activeSecondTab.value = tab.value
  if (activeTab.value === 'skin') {
    getSkinList()
  }
  if (activeTab.value === 'hanging') {
    getHangingList()
  }
  if (activeTab.value === 'collect') {
    getCollectionList()
  }
}

const getSkinList = async () => {
  pageQuery.dataField = activeSecondTab.value.dataField
  pageQuery.sortType = activeSecondTab.value.sortType
  pageQuery.dataRange = activeSecondTab.value.dataRange
  if (activeSecondTab.value.rangeConditionList === null) {
    pageQuery.rangeConditionList = []
  } else {
    pageQuery.rangeConditionList = [
      {
        field: 'price',
        range: null,
        minVal: 100
      },
      {
        field: 'sellNums',
        range: null,
        minVal: 50
      },
      {
        field: 'transactionCount',
        range: 'THREE_DAYS',
        minVal: 30
      }
    ]
  }
  const res = await rankingPage(pageQuery)
  tableData.value = res.data.list
}

const getHangingList = async () => {
  if (activeSecondTab.value === 'knife') {
    const res = await getKnifeRank(hangingQuery)
    hangingList.value = res.data
  }
  if (activeSecondTab.value === 'brick') {
    const res = await getKnifeRank(banzhuanQuery)
    hangingList.value = res.data
  }
}

const getCollectionList = async () => {
  collectionQuery.dataField = activeSecondTab.value
  collectionQuery.content = collectKeyword.value
  const res = await getCollectionRank(collectionQuery)
  collectionList.value = res.data.list
  console.log('getCollectionList', res)
}

const toLadders = () => {
  const localePath = useLocalePath()
  console.log('activeSecondTab', activeSecondTab.value)
  if (activeTab.value === 'hanging') {
    window.open(localePath(`/hanging`))
    return
  }
  window.open(localePath(`/ladders?topTab=${activeTab.value}&secondTab=${activeSecondTab.value?.dataField ?? activeSecondTab.value}`))
}
</script>
<style scoped lang="scss">
:deep(.indexLadder-tabs) {
  .el-tabs__header {
    border: none;
    margin-bottom: 0;
  }
  .el-tabs__item {
    padding: 0 20px;
    font-size: 16px;
  }
  .el-tabs__nav-wrap {
    &::after {
      background-color: transparent;
    }
  }
}
</style>
